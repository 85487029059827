:root {
    --limegreen: hsl(163, 72%, 41%);
    --brightred: hsl(356, 69%, 56%);
    --facebook: hsl(208, 92%, 53%);
    --twitter: hsl(203, 89%, 53%);
    --instagram-start:hsl(37, 97%, 70%);
    --instagram-middle: hsl(5, 77%, 71%);
    --instagram-end: hsl(329, 70%, 58%);
    --youtube: hsl(348, 97%, 39%);
    --toggle-bg-light: hsl(230, 22%, 74%);
    --toggle-button: hsl(228, 46%, 96%);
    --toggle-bg-dark: linear-gradient(225deg, var(--toggle-bg-end) 0%, var(--toggle-bg-start) 98.02%);
    --toggle-bg-start: hsl(210, 78%, 56%);
    --toggle-bg-end:hsl(146, 68%, 55%);
    --toggle-light: hsl(230, 19%, 60%);
    --verydarkblue-bg: hsl(230, 17%, 14%);
    --verydarkblue-top-bg: hsl(232, 19%, 15%);
    --darkdesaturatedblue-card-bg:  hsl(228, 28%, 20%);
    --dark-card-hover: hsl(228, 25%, 27%);
    --desaturatedblue-text: hsl(228, 34%, 66%);
    --white-text: hsl(0, 0%, 100%);
    --white-bg: hsl(0, 0%, 100%);
    --verypaleblue-top-bg: hsl(225, 100%, 98%);
    --lightgrayblue-card-bg: hsl(227, 47%, 96%);
    --light-card-hover: hsl(228, 33%, 91%);
    --darkgrayblue-text: hsl(230, 12%, 44%);
    --verydarkblue-text: hsl(230, 17%, 14%);
    --background: var(--white-bg);
    --text-color: var(--verydarkblue-text);
    --text-color2: var(--darkgrayblue-text);
    --card-bg: var(--lightgrayblue-card-bg);
    --card-hover: var(--light-card-hover);
    --card-text: var(--darkgrayblue-text);
    --count-text: var(--verydarkblue-text);
    --label-text: var(--desaturatedblue-text);
    --toggle: var(--toggle-light);
    --toggle-bg: var(--toggle-bg-light);
    --toggle-button-bg: var(--toggle-button);
}



/*
 - White (BG): hsl(0, 0%, 100%)
- Very Pale Blue (Top BG Pattern): hsl(225, 100%, 98%)
- Light Grayish Blue (Card BG): hsl(227, 47%, 96%)
- Dark Grayish Blue (Text): hsl(228, 12%, 44%)
- Very Dark Blue (Text): hsl(230, 17%, 14%)
*/


@media (prefers-color-scheme: dark) {
    :root {
        --background: var(--verydarkblue-bg);
        --text-color: var(--white-text);
        --card-hover: var(--dark-card-hover);
        --card-bg: var(--darkdesaturatedblue-card-bg);
        --card-text: var(--desaturatedblue-text);
        --count-text: var(--verypaleblue-top-bg);
        --label-text: var(--desaturatedblue-text);
        --text-color2: var(--desaturatedblue-text);
        --toggle-bg: var(--toggle-bg-dark);
        --toggle: var(--white-text);
        --toggle-button-bg: var(--verydarkblue-bg);
    }
} 

body.light {
    --background: var(--white-bg);
    --text-color: var(--verydarkblue-text);
    --text-color2: var(--darkgrayblue-text);
    --card-bg: var(--lightgrayblue-card-bg);
    --card-hover: var(--light-card-hover);
    --card-text: var(--darkgrayblue-text);
    --count-text: var(--verydarkblue-text);
    --label-text: var(--desaturatedblue-text);
    --toggle: var(--toggle-light);
    --toggle-bg: var(--toggle-bg-light);
    --toggle-button-bg: var(--toggle-button);
}

body.dark {
    --background: var(--verydarkblue-bg);
    --text-color: var(--white-text);
    --card-hover: var(--dark-card-hover);
    --card-bg: var(--darkdesaturatedblue-card-bg);
    --card-text: var(--desaturatedblue-text);
    --count-text: var(--verypaleblue-top-bg);
    --label-text: var(--desaturatedblue-text);
    --text-color2: var(--desaturatedblue-text);
    --toggle-bg: var(--toggle-bg-dark);
    --toggle: var(--white-text);
    --toggle-button-bg: var(--verydarkblue-bg);
}


/*  
- Very Dark Blue (BG): hsl(230, 17%, 14%)
- Very Dark Blue (Top BG Pattern): hsl(232, 19%, 15%)
- Dark Desaturated Blue (Card BG): hsl(228, 28%, 20%)
- Desaturated Blue (Text): hsl(228, 34%, 66%)
- White (Text): hsl(0, 0%, 100%)
*/